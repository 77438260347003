export const uz={
  sidebar:{
    welcome: 'Xush kelibsiz!',
    login:'Kirish',
    home:'Bosh sahifa',
    books:'Kitoblar'
  },
  header:{
    placeholder:'Qidiruv'
  },
  homepage:{
    lastread:"Oxirgi o'qilgan kitoblar",
    section:"Bo'limlar",
    new:"Yangi qo'shilganlar",
    popular:'Mashhur kitoblar',
    lib:'Kutubxonada',
    elec:'ta elektron va',
    paper:"qog'oz kitoblar mavjud"
  },
  books:{
    books:'Kitoblar',
    exist:'Bunday kitob mavjud emas'
  },
  filter:{
    name:'Kitob nomi',
    addname:'Kitob nomini kiriting',
    author:'Kitob muallifi',
    addauthor:'Kitob muallifini kiriting',
    lang:'Tili',
    selectlang:'Tilni tanlang',
    uz:"O'zbek tili",
    ru:'Rus tili',
    en:'Ingliz tili',
    cat:'Nashr toifasi',
    selectcat:"Nashr toifasini tanlang",
    sub:'Turkum',
    selectsub:'Turkumni tanlang',
    title:'Filtr',
    search:'Izlash',
  },
  bookview:{
    type:'Nashr turi',
    year:'Nashr yili',
    page:'Sahifa soni',
    lang:'Tili',
    publisher:'Nashriyot',
    download:'Yuklab olish',
    description:'Kitob tavsifi',
    other:"O'xshash asarlar"
  },
  subcategory:{
    exist:"Hozircha ma'lumot yo'q!"
  },
  auth:{
    login:'Login',
    password:'Mahfiy kalit',
    back:'Saytga qaytish',
    visit:'Tashrif',
    ebook:'Elektron kutubxona'
  },
  favorites:{
    found:'Kitob topilmadi!'
  },
  admin:{
    filial:'Filiallar',
    count:'Filiallar soni',
    newfilial:'Yangi filial',
    home:'Bosh sahifa',
    category:'Turkumlar',
    subcategory:"Yo'nalishlar",
    ebook:'Elektron kitoblar',
    paperbook:"Qog'oz kitoblar",
    user:'Foydalanuvchilar',
    exit:'Tizimdan chiqish',
    faculty:'Fakultetlar',
    group:'Guruhlar',
    student:'A`zo talabalar',
    request:"So'rovlar",   
    count:'Umumiy soni',
    addcat:'Yangi turkum',
    edit:"Tahrirlash",
    delete:"O'chirish",
    cencel:'Bekor qilish',
    add:'Kiritish',
    addsub:"Yangi yo'nalish",
    save:'Saqlash',
    newbook:'Yangi kitob',
    download:'Yuklab olish',
    file:'Faylni yuklash',
    editinfo:"Ma'lumotlarni tahrirlash",
    usercount:'Umumiy foydalanuvchilar',
    newuser:'Yangi foydalanuvchi',
    lang:'Tillar',
    newlang:'Yangi til',
    newgroup:'Yangi guruh',
    newstudent:'Yangi talaba',
    answer:'Javob qaytarish',
    send:'Yuborish',
    setting:'Sozlamalar',
    department:"Hodimlar bo'limi",
    newdepartment:"Yangi bo'lim",
    workers:'Hodimlar',
    newworker:'Yangi hodim',
    give:'Kitob berish',
    type:'Turi',
    select:'Tanlang',
    newgive:'Yangi oluvchi',
    help:'Yordam',
    static:'Statistika'
  }
}
import axios from 'axios';

export const filial = {
  state: () => ({
    filials: [],    
    countfilial: [],
  }),
  getters: {
    filials(state) {
      return state.filials;
    },
    countfilial(state) {
      return state.countfilial;
    },
    activefilial(state) {
      return state.filial.filter((cat) => cat.status === 0);
    },
  },
  mutations: {
    filial(state, payload) {
      state.filials = payload;
    },
    countfilial(state, payload) {
      state.countfilial = payload;
    },
    addfilial(state, payload) {
      state.filial.push(payload);
    },
    deletefilial(state, payload) {
      state.filials = state.filials.filter(fil => fil._id !== payload)
    },
    upfilial(state, payload) {
      state.filials = state.filials.map(fil => {
        if (fil._id == payload._id) return payload
        return fil
      })
    },
    statusFilial(state,payload){
      state.filials = state.filials.map((fil) => {
        if (fil._id == payload) return {
          ...fil,
          status: fil.status == 0 ? 1 : 0
        };
        return fil;
      });
    }
  },
  actions: {
    getfilial(context, payload) {
      return axios.get(`${context.getters.url}/filial/${payload}`, {
        headers: context.getters.header,
      });
    },

    deletefilial(context, payload) {
      axios
        .delete(`${context.getters.url}/filial/delete/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('notification', {
              type: 'success',
              message: 'Muvafaqqiyatli',
            });
            context.commit('deletefilial', payload);
          }
        });
    },
    allFilials(context) {
      axios
        .get(`${context.getters.url}/filial/`, {
          headers: context.getters.header,
        })
        .then((res) => {
            // console.log(res.data)
            context.commit('filial', [...res.data.filials])
            context.commit('countfilial', res.data.count);          
        })
    },
    statusFilial({getters,commit},payload){
      axios
        .get(`${getters.url}/filial/change/${payload}`, {
          headers: getters.header,
        })
        .then((res) => {
          // console.log(res.data);
          commit('statusFilial',payload);
            
        });
    },

    addfilial(context, payload) {
      axios
        .post(`${context.getters.url}/filial/create`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('notification', {
              type: 'success',
              message: 'Muvafaqqiyatli',
            });
            context.commit('addfilial', res.data);
          }
        });
    },
    savefilial(context, payload) {
      axios
        .put(`${context.getters.url}/filial`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('notification', {
              type: 'success',
              message: 'Muvaffaqiyatli',
            });
            context.commit('modal', false);
            context.commit('upfilial', payload);
          }
        });
    },
  },
};

<template>
  <div class="sidebar">
    <!-- <div class="sidebar__card">
      
      <div class="sidebar__login">
        <div class="sidebar__login-user">
          {{$t('sidebar.welcome')}}
        </div>
        <router-link to="/login">
          <el-button type="primary" class="sidebar__login-btn" @click="toLogin">{{$t('sidebar.login')}} </el-button>
        </router-link>
      </div>
    </div>
    <el-menu  
      default-active="1" 
      class="el-menu-vertical-demo" 
      background-color="#F4F6F8" 
      text-color="#000" 
      active-text-color="#ffd04b" 
    >
    
      <el-menu-item index="1" class="sidebar__link">
        <router-link to="/">
          <el-icon>
            <homeFilled/>
          </el-icon>
          <span class="sidebar__title">{{$t('sidebar.home')}}</span>
        </router-link>
      </el-menu-item>
      <el-menu-item index="2" class="sidebar__link">
        <router-link to="/allBook" >
          <el-icon>
            <reading/>
          </el-icon>
          <span class="sidebar__title">
            {{$t('sidebar.books')}} 
          </span>
        </router-link>
      </el-menu-item>
    </el-menu> -->
  </div>
</template>

<script>
export default {
methods:{
  toLogin(){
        this.$store.dispatch('setLayout','auth-layout')
      },
  }
}
</script>

<style lang="scss">
@import '@/styles/component/sidebar.scss';
</style>
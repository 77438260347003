<template>
  <div :class="`main searching ${toggleTheme ? '' : 'black'}`">
      <aside-item/>
      <router-view/>
  </div>
</template>

<script>
import AsideItem from '../components/user/UserAside.vue'
import NavMenu from '@/components/NavMenu.vue'
export default {
    components: { AsideItem, NavMenu },
    mounted(){
      
    },
    computed:{
      toggleTheme(){
        return this.$store.getters.toggleTheme
      }
    }
}
</script>

<style lang="scss">
  @import '@/styles/media.scss';
  
</style>
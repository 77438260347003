import { subcategory } from "@/store/data/subcategory";

export const en={
  sidebar:{
    welcome: 'Welcome!',
    login:'Log in',
    home:'Home',
    books:'Books'
  },
  header:{
    placeholder:'Search'
  },
  homepage:{
    lastread:'Last read books',
    section:'Sections',
    new:"News",
    popular:'Popular books'
  },
  books:{
    books:'Books',
    exist:'No such book exists',
  },
  filter:{
    name:'Name of the book',
    addname:"Enter the title of the book",
    author:'The author of the book',
    addauthor:'Enter the author of the book',
    lang:'Language',
    selectlang:'Select a language',
    uz:'Uzbek ',
    ru:'Russian ',
    en:'English',
    cat:'Category',
    selectcat:'Select a category',
    sub:'Subcategory',
    selectsub:'Select Subcategory',
    title:'Filter',
    search:'Search'
  },
  bookview:{
    type:'Publication type',
    year:'Publication year',
    page:'Number of pages',
    lang:'Language',
    publisher:'Publisher',
    download:'Download',
    description:'Description',
    other:'Similar books'
  },
  subcategory:{
    exist:"No information yet!"
  },
  auth:{
    login:'Login',
    password:'Password',
    back:'Return to site',
    visit:'Visit',
    ebook:'Electronic library'
  },
  favorites:{
    found:'Book not found!'
  },
  admin:{
    home:'Home',
    category:'Category',
    subcategory:'Subcategory',
    ebook:'Electron books',
    paperbook:'Paper books',
    user:'Users',
    exit:'Sign out',
    faculty:'Faculties',
    group:'Groups',
    student:'Students',
    
    request:'Requests',
    count:'Total',
    addcat:'New category',
    edit:'Edit',
    delete:'Delete',
    cencel:'Cencel',
    add:'Add',
    addsub:'New subcategory',
    save:'Save',
    newbook:'New book',
    download:'Download',
    file:'Upload file',
    editinfo:'Data editing',
    usercount:'General users',
    newuser:'New user',
    lang:'Languages',
    newlang:'New language',
    newgroup:'New group',
    newstudent:'New student',
    answer:'Answer',
    send:'Send',
    setting:'Settings',
    department:'Personnel department',
    newdepartment:'New department',
    workers:'Employees',
    newworker:'New employee',
    give:'Give a book',
    help:'Help',
    static:'Statistics'
  }
}
import axios from "axios"
import { ElNotification } from 'element-plus'

export const settings = {
  state: () => ({
    faculty:[],
    groups:[],
    allStudent:[],
    countDepartment:0,
    countGroups:0,
    countStudent:0,
    countSection:0,
    countWorker:0,
    statusDepartment:[],
    allRequestSetting:[],
    allDepartment:[],
    allWorker:[]
  }),
  getters:{
    allFaculty(state){
      return state.faculty
    },
    allStudent(state){
      // console.log(state.allStudent)
      return state.allStudent
    },
    allDepartment(state){
      return state.allDepartment
    },
    allWorker(state){
      return state.allWorker
    },
    allRequestSetting(state){
      return state.allRequestSetting
    },
    
    allGroups(state){
      return state.groups
    },
    countDepartment(state){
      return state.countDepartment
    },
    countSection(state){
      return state.countSection
    },
    countWorker(state){
      return state.countWorker
    },
    countGroups(state){
      return state.countGroups
    },
    countStudent(state){
      return state.countStudent
    },
    activeDepartment(state){
      return state.statusDepartment
    },
  },
  mutations:{
    allFaculty(state,payload){
      state.faculty = payload
    },
    faculty(state,payload){
      state.allFaculty = payload
    },
    allGroups(state,payload){
      state.groups = payload
    },
    
    allStudent(state,payload){
      state.allStudent = payload
    },
    allDepartment(state,payload){
      state.allDepartment = payload
    },
    allWorker(state,payload){
      state.allWorker = payload
    },
    allRequest(state,payload){
      state.allRequestSetting = payload
    },
    groups(state,payload){
      state.allGroups = payload
    },
    addstudents(state,payload){
      state.allStudent = payload
    },
    add_students(state,payload){
      state.allStudent = [...payload,...state.allStudent]
    },
    addsection(state,payload){
      state.allDepartment = payload
    },
    addWorker(state,payload){
      state.allWorker.push(payload)
    },
    statusDepartment(state,payload){
      state.statusDepartment = payload
    },
    countGroups(state,payload){
      state.countGroups = payload
    },
    countDepartment(state,payload){
      state.countDepartment = payload
    },
    countStudent(state,payload){
      state.countStudent = payload
    },
    countSection(state,payload){
      state.countSection = payload
    },
    countWorker(state,payload){
      state.countWorker = payload
    },
    deleteFaculty(state,payload){
      let index = state.faculty.findIndex(fac => fac._id == payload)
      if (index !==-1){
          state.faculty.splice(index,1)
      }
    },
    deleteGroups(state,payload){
      let index = state.groups.findIndex(group => group._id == payload)
      if (index !==-1){
          state.groups.splice(index,1)
      }
    },
    deleteStudent(state,payload){
      let index = state.allStudent.findIndex( student=> student._id == payload)
      if (index !==-1){
          state.allStudent.splice(index,1)
      }
    },
    deleteSection(state,payload){
      let index = state.allDepartment.findIndex( student=> student._id == payload)
      if (index !==-1){
          state.allDepartment.splice(index,1)
      }
    },
    deleteWorker(state,payload){
      let index = state.allWorker.findIndex( student=> student._id == payload)
      if (index !==-1){
          state.allWorker.splice(index,1)
      }
    },
    upFaculty(state,payload){
      let index = state.groups.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.groups[index] = payload
      }
    },
    upGroup(state,payload){
      let index = state.faculty.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.faculty[index] = payload
      }
    },
    upStudent(state,payload){
      let index = state.allStudent.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allStudent[index] = payload
      }
    },
    upDepartment(state,payload){
      let index = state.allDepartment.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allDepartment[index] = payload
      }
    },
    upWorker(state,payload){
      let index = state.allWorker.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allWorker[index] = payload
      }
    },
    
  },
  actions:{
    getAllRequest(context,payload){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/requestbook/all`,{
          headers: context.getters.header,
      })
      .then(res=> {
          if (res.status==200){
            
              context.commit('allRequest',res.data)
              context.commit('loading',false)
          }
      })
    },
    editRequest(context,payload){
      return axios.get(`${context.getters.url}/requestbook/${payload}`,{
        headers: context.getters.header
      })
    },
    addAnswerRequest(context,payload){
      axios.put(`${context.getters.url}/requestbook/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          // context.commit('upGroup',payload)
        }
      })
    },
    changeStatusRequest(context,payload){
      axios.get(`${context.getters.url}/requestbook/change/${payload._id}/${payload.status}`)
      .then(res=>{
        if (res.status == 200) {
            ElNotification({
                title: 'So`rov holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    getFaculty(context,payload){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/department`,{
          headers: context.getters.header,
      })
      .then(res=> {
          if (res.status==200){
            
              context.commit('allFaculty',res.data.department)
              context.commit('countDepartment',res.data.count)
              context.commit('loading',false)
          }
      })
    },
    getGroups(context){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/group`,{
          headers: context.getters.header,
      })
      .then(res=> {
          if (res.status==200){
            
              context.commit('allGroups',res.data.group)
              context.commit('countGroups',res.data.count)
              context.commit('loading',false)
          }
      })
    },
    getDepartment(context){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/section`,{
          headers: context.getters.header,
      })
      .then(res=> {
          if (res.status==200){
              context.commit('allDepartment',res.data.sections)
              context.commit('countSection',res.data.count)
              context.commit('loading',false)
          }
      })
    },
    getStudent(context,payload){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/student`,{






        
        params:{...payload},
        headers: context.getters.header,
      })
      .then(res=> {
              context.commit('allStudent',res.data.students)
              context.commit('countStudent',res.data.count)
              context.commit('loading',false)
          
      })
    },
    getWorker(context,payload){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/worker`,{
        params:{section:payload},
        headers: context.getters.header,
      })
      .then(res=> {
          if (res.status==200){
              context.commit('allWorker',res.data.worker)
              context.commit('countWorker',res.data.count)
              context.commit('loading',false)
          }
      })
    },
    statusDepartment(context){
      axios.get(`${context.getters.url}/department/status`,{
          headers: context.getters.header,
      })
      .then(res=> {
          if (res.status==200){
              context.commit('statusDepartment',res.data)
          }
      })
    },
    addFaculty(context,payload){
      axios.post(`${context.getters.url}/department`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
          if (res.status==201){
              context.commit('faculty',res.data)
          }
      })
    },
    addGroups(context,payload){
      axios.post(`${context.getters.url}/group`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
          if (res.status==201){
              context.commit('groups',res.data)
          }
      })
    },
    addStudent(context,payload){
      axios.post(`${context.getters.url}/student`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
          if (res.status==201){
            
              context.commit('addstudents',res.data)
          }
      })
    },
    addDepartment(context,payload){
      axios.post(`${context.getters.url}/section`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
          if (res.status==201){
            context.commit('notification',{
              type:'success',
              message:'Muvafaqqiyatli'
          })
            context.commit('addsection',res.data)
          }
      })
    },
    addWorker(context,payload){
      axios.post(`${context.getters.url}/worker`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
          if (res.status==201){
            context.commit('notification',{
              type:'success',
              message:'Muvafaqqiyatli'
          })
            context.commit('addWorker',res.data)
          }
      })
    },
    deleteFaculty(context,payload){
      axios.delete(`${context.getters.url}/department/${payload}`,{
          headers: context.getters.header
      })
      .then(res => {
          if (res.status == 200){
              context.commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              context.commit('deleteFaculty',payload)
          }
      })
    },
    deleteGroups(context,payload){
      axios.delete(`${context.getters.url}/group/${payload}`,{
          headers: context.getters.header
      })
      .then(res => {
          if (res.status == 200){
              context.commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              context.commit('deleteGroups',payload)
          }
      })
    },
    deleteStudents(context,payload){
      axios.delete(`${context.getters.url}/student/${payload}`,{
          headers: context.getters.header
      })
      .then(res => {
          if (res.status == 200){
              context.commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              context.commit('deleteStudent',payload)
          }
      })
    },
    deleteDepartment(context,payload){
      axios.delete(`${context.getters.url}/section/${payload}`,{
          headers: context.getters.header
      })
      .then(res => {
          if (res.status == 200){
              context.commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              context.commit('deleteSection',payload)
          }
      })
    },
    deleteWorker(context,payload){
      axios.delete(`${context.getters.url}/worker/${payload}`,{
          headers: context.getters.header
      })
      .then(res => {
          if (res.status == 200){
              context.commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              context.commit('deleteWorker',payload)
          }
      })
    },
    editFaculty(context,payload){
      return axios.get(`${context.getters.url}/department/${payload}`,{
        headers: context.getters.header
      })
    },
    editGroups(context,payload){
      return axios.get(`${context.getters.url}/group/${payload}`,{
        headers: context.getters.header
      })
    },
    editStudent(context,payload){
      return axios.get(`${context.getters.url}/student/${payload}`,{
        headers: context.getters.header
      })
    },
    editDepartment(context,payload){
      return axios.get(`${context.getters.url}/section/${payload}`,{
        headers: context.getters.header
      })
    },
    editWorker(context,payload){
      return axios.get(`${context.getters.url}/worker/${payload}`,{
        headers: context.getters.header
      })
    },
    saveFaculty(context,payload){
      axios.put(`${context.getters.url}/department/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          context.commit('upFaculty',payload)
        }
      })
    },
    saveGroups(context,payload){
      axios.put(`${context.getters.url}/group/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          context.commit('upGroup',payload)
        }
      })
    },
    saveStudent(context,payload){
      axios.put(`${context.getters.url}/student/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          context.commit('upStudent',payload)
        }
      })
    },
    saveDepartment(context,payload){
      axios.put(`${context.getters.url}/section/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          // context.commit('upStudent',payload)
        }
      })
    },
    saveWorker(context,payload){
      axios.put(`${context.getters.url}/worker/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          // context.commit('upStudent',payload)
        }
      })
    },
    changeStatusFaculty(context,payload){
      axios.get(`${context.getters.url}/department/change/${payload._id}/${payload.status}`)
      .then(res=>{
        if (res.status == 200) {
            context.commit('upFaculty',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    changeStatusGroup(context,payload){
      axios.get(`${context.getters.url}/group/change/${payload._id}/${payload.status}`)
      .then(res=>{
        if (res.status == 200) {
            context.commit('upGroup',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    changeStatusStudent(context,payload){
      axios.get(`${context.getters.url}/student/change/${payload._id}/${payload.status}`)
      .then(res=>{
        if (res.status == 200) {
            context.commit('upStudent',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    changeStatusSection(context,payload){
      axios.get(`${context.getters.url}/section/change/${payload._id}/${payload.status}`)
      .then(res=>{
        if (res.status == 200) {
            context.commit('upDepartment',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    changeStatusWorker(context,payload){
      axios.get(`${context.getters.url}/worker/change/${payload._id}/${payload.status}`)
      .then(res=>{
        if (res.status == 200) {
            context.commit('upWorker',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
  }
}
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  {
    path: '/sub/:id',
    name: 'subcategory',
    component: () =>
      import(/* webpackChunkName: "sub" */ '../views/SubcategoryView.vue'),
  },
  {
    path: '/category/:id',
    name: 'categorybook',
    component: () =>
      import(/* webpackChunkName: "products" */ '../views/CategoryView.vue'),
  },

  {
    path: '/allBook',
    name: 'allBook',
    component: () =>
      import(/* webpackChunkName: "allBook" */ '../views/AllBook.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () =>
      import(/* webpackChunkName: "search" */ '../components/Search.vue'),
  },

  {
    path: '/favorites',
    name: 'favorites',
    component: () =>
      import(/* webpackChunkName: "favorites" */ '../components/Favorites.vue'),
  },
  {
    path: '/book/:id',
    name: 'book',
    component: () =>
      import(/* webpackChunkName: "book" */ '../views/BookView.vue'),
  },
  {
    path: '/bookview',
    name: 'bookview',
    component: () =>
      import(/* webpackChunkName: "bookview" */ '../views/BookViewAll.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
    meta: {
      layout: 'auth-layout',
    },
  },
  {
    path: '/students',
    name: 'students',
    component: () =>
      import(/* webpackChunkName: "students" */ '../views/admin/HomeAdmin.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(
        /* webpackChunkName: "sign-in" */ '../components/profile/Profile.vue'
      ),
    children: [
      {
        path: 'set',
        name: 'set',
        component: () =>
          import(
            /* webpackChunkName: "set" */ '../components/profile/ProfileSetting.vue'
          ),
      },
      {
        path: 'download',
        name: 'download',
        component: () =>
          import(
            /* webpackChunkName: "download" */ '../components/profile/ProfileDownload.vue'
          ),
      },
      {
        path: 'request',
        name: 'request',
        component: () =>
          import(
            /* webpackChunkName: "request" */ '../components/profile/ProfileRequest.vue'
          ),
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/HomeAdmin.vue'),
    children: [
      {
        path: 'booklist',
        name: 'books',
        component: () =>
          import(
            /* webpackChunkName: "booklist" */ '../views/admin/BooksList.vue'
          ),
      },
      {
        path: 'paperbook',
        name: 'paperbook',
        component: () =>
          import(
            /* webpackChunkName: "paperbook" */ '../views/admin/PaperBook.vue'
          ),
      },
      {
        path: 'givebook',
        name: 'givebook',
        component: () =>
          import(
            /* webpackChunkName: "paperbook" */ '../views/admin/GiveBook.vue'
          ),
      },
      {
        path: 'addbook',
        name: 'addbook',
        component: () =>
          import(
            /* webpackChunkName: "addbook" */ '../views/admin/AddBook.vue'
          ),
      },
      {
        path: 'editbook/:id',
        name: 'editbook',
        component: () =>
          import(
            /* webpackChunkName: "editbook" */ '../views/admin/EditPaperBook.vue'
          ),
      },
      {
        path: 'prof',
        name: 'prof',
        component: () =>
          import(
            /* webpackChunkName: "prof" */ '../components/admin/ProfileAdmin.vue'
          ),
      },
      {
        path: 'help',
        name: 'help',
        component: () =>
          import(/* webpackChunkName: "help" */ '../views/admin/Help.vue'),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'student',
        name: 'student',
        component: () =>
          import(
            /* webpackChunkName: "student" */ '../views/setting/Students.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'department',
        name: 'department',
        component: () =>
          import(
            /* webpackChunkName: "        path: 'department',
        " */ '../views/setting/Department.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
    ],
  },

  {
    path: '/setting',
    name: 'setting',
    children: [
      {
        path: '',
        name: 'settingpage',
        component: () =>
          import(/* webpackChunkName: "setting" */ '../views/SettingPage.vue'),
      },
      {
        path: 'subs',
        name: 'subcategories',
        component: () =>
          import(
            /* webpackChunkName: "subs" */ '../views/admin/SubcategoryList.vue'
          ),
      },
      {
        path: 'static',
        name: 'static',
        component: () =>
          import(
            /* webpackChunkName: "subs" */ '../views/admin/StatisticPage.vue'
          ),
      },
      {
        path: 'cats',
        name: 'category',
        component: () =>
          import(
            /* webpackChunkName: "cats" */ '../views/admin/CategoryList.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'language',
        name: 'language',
        component: () =>
          import(
            /* webpackChunkName: "language" */ '../views/setting/Language.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'faculty',
        name: 'faculty',
        component: () =>
          import(
            /* webpackChunkName: "faculty" */ '../views/setting/Faculty.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'groups',
        name: 'groups',
        component: () =>
          import(
            /* webpackChunkName: "groups" */ '../views/setting/Groups.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'workers/:id',
        name: 'workers',
        component: () =>
          import(
            /* webpackChunkName: "        path: 'workers',
        " */ '../views/setting/Workers.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'requestAdmin',
        name: 'requestAdmin',
        component: () =>
          import(
            /* webpackChunkName: "requestAdmin" */ '../views/setting/Request.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
      {
        path: 'filial',
        name: 'filial',
        component: () =>
          import(
            /* webpackChunkName: "        path: 'filial',
        " */ '../views/setting/FilialList.vue'
          ),
        meta: {
          layout: 'admin-layout',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

import axios from "axios"

export const dashboard ={
  state: () => ({
    ebooks:[],
    pbooks:[],
    giveBooks:[]
  }),
  getters:{
    ebooks(state) {
			return state.ebooks
		},
    pbooks(state) {
			return state.pbooks
		},
    giveBooks(state) {
			return state.giveBooks
		},
  },
  mutations:{
    ebooks(state,payload){
      state.ebooks = payload
    },
    pbooks(state,payload){
      state.pbooks = payload
    },
    giveBooks(state,payload){
      state.giveBooks = payload
    },
  },
  actions:{
    allEbook(context){
      axios.get(`${context.getters.url}/book/forhome`,{
          headers: context.getters.header
      })
      .then(res=> {
          if (res.status==200){
            let books = res.data
            books = books.map(book => {
							book.author = book.author ? JSON.parse(book.author) : ''
							return book
						})
              context.commit('ebooks',res.data)
          }
      })
    },
    allPbook(context){
      axios.get(`${context.getters.url}/paperbook/forhome`,{
          headers: context.getters.header
      })
      .then(res=> {
          if (res.status==200){
            
              context.commit('pbooks',res.data)
          }
      })
    },

  }
}
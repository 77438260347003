import { buttonGroupContextKey } from "element-plus";

export const ru={
  sidebar:{
    welcome: 'Добро пожаловать!',
    login:'Вход',
    home:'Главная',
    books:'Книги'
  },
  header:{
    placeholder:'Поиск'
  },
  homepage:{
    lastread:'Последние прочитанные книги',
    section:'Разделы',
    new:'Новые',
    popular:'Популярные книги',
  },
  books:{
    books:'Книги',
    exist:'Такой книги не существует'
  },
  filter:{
    name:'Название книги',
    addname:'Введите название книги',
    author:'Автор книги',
    addauthor:'Введите автора книги',
    lang:'Язык',
    selectlang:'Выберите язык',
    uz:'Узбекский',
    ru:'Русский',
    en:'Английский',
    cat:'Категория',
    selectcat:'Выберите категорию',
    sub:'Подкатегория',
    selectsub:'Выберите подкатегория',
    title:'Фильтр',
    search:'Поиск',

  },
  bookview:{
    type:'Тип публикации',
    year:'Год публикации',
    page:'Количество страниц',
    lang:'Язык',
    publisher:'Издательство',
    download:'Скачать',
    description:'Описание книг',
    other:'Похожие книги'
  },
  subcategory:{
    exist:"Пока нет информации!"
  },
  auth:{
    login:'Логин',
    password:'Пароль',
    back:"Вернуться на сайт",
    visit:'Посещение',
    ebook:'Электронная библиотека'
  },
  favorites:{
    found:'Книга не найдена!'
  },
  admin:{
    home:'Главная',
    category:'Категория',
    subcategory:'Сфера',
    ebook:'Электронные книги',
    paperbook:'Бумажные книги',
    user:'Пользователи',
    exit:'Bыход',
    faculty:'Факультеты',
    group:'Группы',
    student:'Студенты',
    
    request:'Запросы',
    count:'Общие количество',
    addcat:'Новая категория',
    edit:'Редактирование',
    delete:'Удалить',
    cencel:'Отмена',
    add:'Добавить',
    addsub:'Новое cфера',
    save:'Сохранить',
    newbook:'Новая книга',
    download:'Скачать',
    file:'Загрузить файл',
    editinfo:'Редактирование данных',
    usercount:'Общие пользователи',
    newuser:'Новый пользователь',
    lang:'Языки',
    newlang:'Новый язык',
    newgroup:'Новая группа',
    newstudent:'Новый студент',
    answer:'Ответить',
    send:'Отправить',
    setting:'Настройки',
    department:'Отдел кадров',
    newdepartment:'Новый отдел',
    workers:'Сотрудники',
    newworker:'Новый cотрудник',
    newgive:'',
    help:'Помощь',
    static:'Статистика'
  }
}
import axios from "axios"
import cookie from 'vue-cookies'

export const subcategory = {
  state: () => ({
    subcategory: [],
    allSubcategory: [],
    onesubcategory: {},
  }),
  getters: {
    subcategory(state) {
      return state.subcategory
    },
    getBySubcategory(state) {
      return state.allSubcategory
    },
    activeSubcategory(state) {
      return state.subcategory.filter(cat => cat.status === 0)
    },
    onesubcategory(state) {
      return state.onesubcategory
    },
  },
  mutations: {
    subcategory(state, payload) {
      state.subcategory = payload
    },
    setSubcategory(state, payload) {
      if (cookie.isKey('favapp')) {
				let favs = JSON.parse(cookie.get('favapp'))
        
          payload.books = payload.books.map(item => {
            item.fav = favs.includes(item._id);
            return item
          })
        
			}
      state.allSubcategory = payload
      
    },
    onesubcategory(state, payload) {
      state.onesubcategory = payload
    },
    addSubcategory(state, payload) {
      state.subcategory.push(payload)
    },
    deleteSubcategory(state, payload) {
      let index = state.subcategory.findIndex(cat => cat._id == payload)
      if (index !== -1) {
        state.subcategory.splice(index, 1)
      }
    },
    upSubcategory(state, payload) {
      let index = state.subcategory.findIndex(cat => cat._id == payload._id)
      if (index !== -1) {
        state.subcategory[index] = payload
        state.subcategory[index].status = payload.status ? 0 : 1
      }
    },
    setSubFav(state, payload) {
			let index = state.allSubcategory.books.findIndex(book => book._id == payload.id)
			state.allSubcategory.books[index].fav = payload.fav
		},
  },
  actions: {
    setSubFav(context, payload) {
			context.commit('setSubFav', payload)
		},
    getSubcategory(context, payload) {
      return axios.get(`${context.getters.url}/subcategory/edit/${payload}`, {
        headers: context.getters.header
      })
    },
    viewSubcategory(context, payload) {
      axios.get(`${context.getters.url}/subcategory/edit/${payload}`, {
        headers: context.getters.header
      }).then(res => {
        if (res.status === 200) {
          context.commit('onesubcategory', res.data)
        }
      })
    },
    deleteSubcategory(context, payload) {
      axios.delete(`${context.getters.url}/subcategory/delete/${payload}`, {
          headers: context.getters.header
        })
        .then(res => {
          if (res.status == 200) {
            context.commit('notification', {
              type: 'success',
              message: 'Muvafaqqiyatli'
            })
            context.commit('deleteSubcategory', payload)
          }
        })
    },
    allSubcategory(context) {
      axios.get(`${context.getters.url}/subcategory/`, {
          headers: context.getters.header
        })
        .then(res => {
          if (res.status == 200) {
            res.data = res.data.map(item => {
              item.cat = item.category.title
              return item
            })
            context.commit('subcategory', res.data)
          }
        })
    },
    async allsubcategoryCard({commit,getters}, payload) {
      commit('loading',true)
      let res = await axios.get(`${getters.url}/category/sub/${payload.category}`, {
        params:{subcategory:payload.subcategory,limit:payload.limit},
        headers: getters.header
      })
      
      if (res.status == 200) {
        commit('loading',false)
        let a = res.data
        if(a.books){
            a.books = a.books.map(book => {
              book.author = book.author ? JSON.parse(book.author) : ''
              return book
            })
        }
        commit('setSubcategory', a)
      }
        
      
      
    },
    addSubcategory(context, payload) {
      axios.post(`${context.getters.url}/subcategory/create`, payload, {
          headers: context.getters.header
        })
        .then(res => {
          if (res.status == 200) {
            context.commit('notification', {
              type: 'success',
              message: 'Muvafaqqiyatli'
            })
            context.commit('addSubcategory', res.data)
          }
        })
    },
    saveSubcategory(context, payload) {
      axios.post(`${context.getters.url}/subcategory/save/${payload._id}`, payload, {
          headers: context.getters.header
        })
        .then(res => {
          if (res.status == 200) {
            context.commit('notification', {
              type: 'success',
              message: 'Muvafaqqiyatli'
            })
            context.commit('modal', false)
            context.commit('upSubcategory', payload)
          }
        })
    }
  }
}
import axios from "axios";
import cookie from "vue-cookies";

export const book = {
  state: () => ({
    books: [],
    book: {
      title: "",
    },
    bookscount: 0,
    authors: [],
    language: {
      uz: ["O`zbek", "Rus", "Ingliz", "Nemis", "Koreys", "Arab", "Fransuz"],
    },
    lang: "uz",
    others: [],
    resbooks: [],
    allBook: [],
    popularBook: [],
    allview: [],
    searchHome: [],
  }),
  getters: {
    bookscount(state) {
      return state.bookscount;
    },

    allBook(state) {
      return state.allBook;
    },
    popularBook(state) {
      return state.popularBook;
    },
    resbooks(state) {
      return state.resbooks;
    },
    searchHomeGet(state) {
      return state.searchHome;
    },

    others(state) {
      return state.others;
    },
    language(state) {
      return state.language[state.lang];
    },
    authors(state) {
      return state.authors;
    },
    books(state) {
      return state.books;
    },
    book(state) {
      return state.book;
    },
    allview(state) {
      return state.allview;
    },

    lastbook(state) {
      return state.book.slice(0, 11);
    },
  },
  mutations: {
    bookscount(state, payload) {
      state.bookscount = payload;
    },

    bookAll(state, payload) {
      if (cookie.isKey("favapp")) {
        let favs = JSON.parse(cookie.get("favapp"));
        payload = payload.map((item) => {
          item.fav = favs.includes(item._id);
          return item;
        });
      }
      state.allBook = payload;
    },
    popularBook(state, payload) {
      if (cookie.isKey("favapp")) {
        let favs = JSON.parse(cookie.get("favapp"));
        payload = payload.map((item) => {
          item.fav = favs.includes(item._id);
          return item;
        });
      }
      state.popularBook = payload;
    },
    search(state, payload) {
      state.resbooks = payload;
    },
    searchHome(state, payload) {
      state.searchHome = payload;
    },

    others(state, payload) {
      if (cookie.isKey("favapp")) {
        let favs = JSON.parse(cookie.get("favapp"));

        payload = payload.map((item) => {
          if (item) {
            item.fav = favs.includes(item._id);
            return item;
          }
        });
      }
      state.others = payload;
    },
    authors(state, payload) {
      state.authors = payload;
    },
    book(state, payload) {
      state.book = payload;
    },
    allview(state, payload) {
      state.allview = payload;
    },
    books(state, payload) {
      if (cookie.isKey("favapp")) {
        let favs = JSON.parse(cookie.get("favapp"));
        payload = payload.map((item) => {
          item.fav = favs.includes(item._id);
          return item;
        });
      }
      state.books = payload;
    },
    addBook(state, payload) {
      state.books.push(payload);
    },

    deleteBook(state, payload) {
      state.books = state.books.filter((book) => book._id !== payload);
    },
    deleteAllBook(state, payload) {
      state.allBook = state.allBook.filter((book) => book._id !== payload);
    },
    upBook(state, payload) {
      state.books = state.books.map((book) => {
        if (book._id === payload._id) {
          let year = new Date(payload.year);
          payload.year = year.getFullYear() + " yil";
          payload.author = JSON.parse(payload.author);
          return payload;
        }
        return book;
      });
    },
    setFav(state, payload) {
      let index = state.books.findIndex((book) => book._id == payload.id);
      state.books[index].fav = payload.fav;
    },
    setToFav(state, payload) {
      let index = state.others.findIndex((book) => book._id == payload.id);
      state.others[index].fav = payload.fav;
    },
    setAllFav(state, payload) {
      let index = state.allBook.findIndex((book) => book._id == payload.id);
      state.allBook[index].fav = payload.fav;
    },
  },
  actions: {
    setFav(context, payload) {
      context.commit("setFav", payload);
    },
    setToFav(context, payload) {
      context.commit("setToFav", payload);
    },
    setAllFav(context, payload) {
      context.commit("setAllFav", payload);
    },

    searchList(context, payload) {
      axios.get(`${context.getters.url}/book/search/${payload}`).then((res) => {
        if (res.data !== "error") {
          let data = res.data;
          let books = data.books;
          if (books.length > 0) {
            books = books.map((book) => {
              if (book.author) {
                book.author = JSON.parse(book.author).toString();
              }
              return book;
            });
          }
          context.commit("search", res.data);
        } else {
          context.commit("search", []);
        }
      });
    },
    searchHome(context, payload) {
      axios
        .get(`${context.getters.url}/book/searchAll/${payload}`)
        .then((res) => {
          if (res.data !== "error") {
            context.commit("searchHome", res.data);
          } else {
            context.commit("searchHome", []);
          }
        });
    },
    async showBook({ commit, getters }, payload) {
      if (!payload) return false;
      commit("book", {});

      let res = await axios.get(`${getters.url}/book/show/${payload}`, {
        headers: getters.header,
      });
      let book = res.data;
      let year = new Date(book.year);
      if (book.author) {
        book.author = JSON.parse(book.author);
        book.author = book.author.toString();
      }
      book.year = year.getFullYear();
      if (book.language == 0) {
        book.language = "O'zbek tili";
      } else if (book.language == 1) {
        book.language = "Rus tili";
      } else {
        book.language = "Ingliz tili";
      }
      if (book.others?.length > 0) {
        book.others = book.others.map((item) => {
          if (item.author) {
            item.author = JSON.parse(item.author);
          }
          return item;
        });
        commit("others", book.others);
      }
      book.cat = book.category?.title ? book.category?.title : "";
      commit("book", book);
    },
    getBooks(context, payload) {
      if (payload) {
        axios
          .get(`${context.getters.url}/book/bytype/${payload}`, {
            headers: context.getters.header,
          })
          .then((res) => {
            if (res.status === 200) {
              context.commit("books", res.data);
            }
          });
      }
    },
    bookByCategory(context, payload) {
      axios
        .get(`${context.getters.url}/book/bycategory/all/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status === 200) {
            let books = res.data;
            if (books) {
              books = books.map((book) => {
                let year = new Date(book.year);
                if (book.author) {
                  book.author = JSON.parse(book.author).toString();
                }
                book.year = year.getFullYear();
                return book;
              });
            }
            context.commit("books", books);
          }
        });
    },
    getBook(context, payload) {
      return axios.get(`${context.getters.url}/book/edit/${payload}`, {
        headers: context.getters.header,
      });
    },
    deleteBook(context, payload) {
      axios
        .delete(`${context.getters.url}/book/delete/${payload}`, {
          headers: context.getters.header,
        })
        .then(() => {
          context.commit("deleteAllBook", payload);
        });
    },
    allBookList(context, payload) {
      context.commit("loading", true);
      axios
        .get(`${context.getters.url}/book/all`, {
          headers: context.getters.header,
        })
        .then((res) => {
          context.commit("loading", false);
          if (res.status == 200) {
            let { books, count } = res.data;
            books = books.map((book) => {
              book.author = book.author ? JSON.parse(book.author) : "";
              let year = new Date(book.year);
              book.year = year.getFullYear() + " yil";
              return book;
            });
            context.commit("bookscount", count);
            context.commit("books", books);
          }
        });
    },
    allBook(context, payload) {
      context.commit("loading", true);
      axios
        .get(`${context.getters.url}/book`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          let { book, count } = res.data;

          book = book.map((book) => {
            let year = new Date(book.year);
            book.year = year.getFullYear() + " yil";
            book.author = book.author ? JSON.parse(book.author) : "";
            return book;
          });

          context.commit("bookscount", count);
          context.commit("bookAll", book);
          context.commit("loading", false);
        });
    },
    popularBooks(context) {
      axios
        .get(`${context.getters.url}/book/popular`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            let { book } = res.data;
            if (book) {
              book = book.map((book) => {
                book.author = book.author ? JSON.parse(book.author) : "";
                return book;
              });
            }
            context.commit("popularBook", book);
          }
        });
    },
    copyBook(context, payload) {
      axios
        .post(`${context.getters.url}/book/copy`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
          }
        });
    },
    addBook(context, payload) {
      axios
        .post(`${context.getters.url}/book/create`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("addBook", res.data);
          }
        });
    },
    saveBook(context, payload) {
      axios
        .post(`${context.getters.url}/book/save/${payload._id}`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("notification", {
              type: "success",
              message: "Muvaffaqiyatli",
            });
            context.commit("modal", false);
            context.dispatch("allBook");
          }
        });
    },
  },
};

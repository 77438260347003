<template>
  <div class="navigation">
    <div class="navigation__menu">
      <div class="navigation__list active" @click="goPage()">
      <router-link to="/">
        <span class="navigation__icon">
          <el-icon><HomeFilled/></el-icon>
        </span>
        <span class="navigation__text">Bosh sahifa</span>
      </router-link>
    </div>
    <div class="navigation__list" @click="goPage()">
      <router-link to="/allBook">
        <span class="navigation__icon">
          <el-icon><Reading/></el-icon>
        </span>
        <span class="navigation__text">Kitoblar</span>
      </router-link>
    </div>
    <div class="navigation__list" @click="goPage()">
      <router-link to="/search">
        <span class="navigation__icon">
          <el-icon><Search/></el-icon>
        </span>
        <span class="navigation__text">Qidiruv</span>
      </router-link>
    </div>
    <div class="navigation__list" @click="goPage()">
      <router-link to="">
        <span class="navigation__icon">
          <img src="../assets/icons/like.png" alt="">
        </span>
        <span class="navigation__text">Tanlanganlar</span>
      </router-link>
    </div>
    <div class="navigation__list" @click="goPage()">
      <router-link to="/sign-in">
        <span class="navigation__icon" >
          <el-icon><UserFilled/></el-icon>
        </span>
        <span class="navigation__text">Profil</span>
      </router-link>
    </div>
    
    <div class="navigation__indicator"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    goPage(){
      const list = document.querySelectorAll('.navigation__list');
      function activeLink(){
        list.forEach((item) =>
          item.classList.remove('active'))
          this.classList.add('active')
      }
      list.forEach((item) =>
      item.addEventListener('click',activeLink))
    },
    toLogin(){
      this.$store.dispatch('setLayout','auth-layout')
    },
  }
}
</script>

<style lang="scss">
@import '@/styles/navmenu.scss';
</style>
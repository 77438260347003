import axios from "axios";
import cookie from 'vue-cookies'

export const favorites ={
  state: () => ({
    favorites:[],
    listcookie: []
  }),
  getters:{
    favorites(state){
      return state.favorites
    },
  },
  mutations:{
    favorites(state,payload){
      state.favorites = payload
    },
    setFavs(state,payload){
      let index = state.favorites.findIndex(book => book._id == payload.id)
      state.favorites[index].fav = payload.fav
  },
  },
  actions:{
    
    getFavorites(context){
      let list = []
      if(cookie.isKey('favapp')){
        list = JSON.parse(cookie.get('favapp'))
        context.commit('favorites',list) 
      }
      axios.get(`${context.getters.url}/book/forcookie`,{
        params:{list:list},
        headers: context.getters.header
      }).then(res =>{
        let books = res.data
        books = books.map(book => {
          book.author = book.author ? JSON.parse(book.author): ''
          return book
        })
        context.commit('favorites',res.data)
      })
    }
  }
}
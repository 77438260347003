<template>
  <div  :class="`main searching ${toggleTheme ? '' : 'black'}`">
    <aside-item/>
    <router-view/>
  </div>
</template>

<script>
import StudentAside from '../components/student/StudentAside.vue'
import AsideItem from '../components/user/UserAside.vue'
export default {
  components: { AsideItem, StudentAside, },
  computed:{
      toggleTheme(){
        return this.$store.getters.toggleTheme
      }
    }
}
</script>

<style lang="scss">
</style>
import axios from "axios"
import { ElNotification } from 'element-plus'

export const languageBook = {
  state:() => ({
    allLanguage:[],
    count:0,
    editLanguage:{},
    changeCategory:[]
  }),
  getters:{
    count(state){
      return state.count
    },
    allLanguage(state){
      return state.allLanguage
    },
    editLanguage(state){
      return state.editLanguage
    },
    changeCategory(state){
      return state.changeCategory
    }
  },
  mutations:{
    allLanguage(state,payload){
      state.allLanguage = payload
    },
    count(state,payload){
      state.count = payload
    },
    deleteLanguage(state,payload){
      let index = state.allLanguage.findIndex(lang => lang._id == payload)
      if (index !==-1){
          state.allLanguage.splice(index,1)
      }
    },
    editLanguage(state,payload){
      state.editLanguage = payload
    },
    upLanguage(state,payload){
      let index = state.allLanguage.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allLanguage[index] = payload
      }
    },
    changeCategory(state,payload){
      state.changeCategory = payload
    }
  },
  actions:{
    allLanguage(context,payload){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/language`,{
          headers: context.getters.header,
      })
      .then(res=> {
          if (res.status==200){
            
              context.commit('allLanguage',res.data.language)
              context.commit('count',res.data.count)
              context.commit('loading',false)
          }
      })
    },
    editLanguage(context,payload){
      return axios.get(`${context.getters.url}/language/${payload}`,{
        headers: context.getters.header
    })
    },
    saveLanguage(context,payload){
      axios.put(`${context.getters.url}/language/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          context.commit('modal',false)
          context.commit('upLanguage',payload)
        }
      })
    },
    deleteLanguage(context,payload){
      axios.delete(`${context.getters.url}/language/${payload}`,{
          headers: context.getters.header
      })
      .then(res => {
          if (res.status == 200){
              context.commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              context.commit('deleteLanguage',payload)
          }
      })
    },
    changeStatusLanguage(context,payload){
      axios.get(`${context.getters.url}/language/change/${payload._id}/${payload.status}`)
      .then(res=>{
        if (res.status == 200) {
            context.commit('upLanguage',res.data)
            ElNotification({
                title: 'Til holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
  }
}
<template>
  <router-view/>
</template>

<script>

  export default {
    
  }
</script>

<style lang="scss" scoped>
  @import '../styles/view/auth.scss';
</style>
import axios from "axios"

export const category = {
    state: () => ({
        category: [],
        onecategory:{},
        countcategory: []
    }),
    getters: {
        category(state){
            return state.category
        },
        countcategory(state){
            return state.countcategory
        },
        activeCategory(state){
            return state.category.filter(cat => cat.status === 0)
        },
        onecategory(state){
            return state.onecategory
        }
    },
    mutations:{
        category(state,payload){
            state.category = payload
        },
        countcategory(state,payload){
            state.countcategory = payload
        },
        onecategory(state,payload){
            state.onecategory = payload
        },
        addCategory(state,payload){
            state.category.push(payload)
        },
        deleteCategory(state,payload){
            let index = state.category.findIndex(cat => cat._id == payload)
            if (index !==-1){
                state.category.splice(index,1)
            }
        },
        upCategory(state,payload){
            let index = state.category.findIndex(cat => cat._id == payload._id)
            if (index !==-1){
                state.category[index] = payload
                state.category[index].status = payload.status ? 0 : 1
            }
        }
    },
    actions:{
        getCategory(context,payload){
            return axios.get(`${context.getters.url}/category/edit/${payload}`,{
                headers: context.getters.header
            })
        },
        viewCategory(context,payload){
            axios.get(`${context.getters.url}/category/edit/${payload}`,{
                headers: context.getters.header
            }).then(res => {
                if (res.status === 200){
                    context.commit('onecategory',res.data)
                }
            })
        },
        deleteCategory(context,payload){
            axios.delete(`${context.getters.url}/category/delete/${payload}`,{
                headers: context.getters.header
            })
            .then(res => {
                if (res.status == 200){
                    context.commit('notification',{
                        type:'success',
                        message:'Muvafaqqiyatli'
                    })
                    context.commit('deleteCategory',payload)
                }
            })
        },
        allCategory(context){
            axios.get(`${context.getters.url}/category/`,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('category',res.data)
                }
            })
        },
        allCategoryCount(context){
            axios.get(`${context.getters.url}/category/count`,{
                headers: context.getters.header
            })
            .then(res=> {
                context.commit('countcategory',res.data)
            })
        },
        addCategory(context,payload){
            axios.post(`${context.getters.url}/category/create`,payload,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('notification',{
                        type:'success',
                        message:'Muvafaqqiyatli'
                    })
                    context.commit('addCategory',res.data)
                }
            })
        },
        saveCategory(context,payload){
            axios.post(`${context.getters.url}/category/save/${payload._id}`,payload,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('notification',{
                        type:'success',
                        message:'Muvaffaqiyatli'
                    })
                    context.commit('modal',false)
                    context.commit('upCategory',payload)
                }
            })
        }
    }
}
<template>
  <header :class="`header  ${toggleTheme ? '' : 'black'}`">
    <div class="header__left" v-if="headerSearch">
      <router-link to="/" class="header__logo">
        <img src="@/assets/logoarm.png" />
      </router-link>
      <div class="header__search">
        <el-icon class="header__icon"><Search /></el-icon>
        <el-input
          class="header__input"
          type="text"
          clearable
          v-model="searchHeader"
          :placeholder="$t('header.placeholder')"
          size="large"
          style="width: 600px"
          @keypress.enter="goBook(searchHeader)"
        />
        <el-scrollbar
          height="300px"
          class="header__list"
          v-if="searchHomeGet?.length > 0 && searchHeader?.length > 0"
        >
          <div>
            <span
              @click="goBook(item.title)"
              v-for="item of searchHomeGet"
              :key="item._id"
            >
              <div class="name">
                <el-icon><Search /></el-icon>
                <b>{{ item.title }}</b>
              </div>
            </span>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="header__center" v-if="headerLogo">
      <router-link to="/" class="header__logo">
        <img src="@/assets/logoarm.png" />
      </router-link>
      <div class="header__section">
        <ul>
          <li>
            <router-link
              :to="`/sub/${cat._id}`"
              class="header__section-col"
              v-for="(cat, index) of category"
              :key="index"
            >
              {{ cat.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="header__menu"
      @click="toggleMobile"
      v-show="mobile"
      :class="{ 'icon-active': mobileSide }"
    >
      <!-- <img src="@/assets/icons/menu.png" alt="" v-if="role !== 'admin' "> -->
      <svg
        v-if="role !== 'admin'"
        width="40px"
        height="40px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 7a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm1 4a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3z"
          fill="#000000"
        />
      </svg>
    </div>
    <div class="header__sidebar">
      <transition name="mobile-side">
        <div
          class="header__mobile"
          v-show="mobileSide"
          @click.self="toggleMobile"
        >
          <div class="header__close" @click="toggleMobile">&times;</div>
          <router-link to="/" class="header__logoMobile">
            <img src="@/assets/logoarm.png" alt="" />
          </router-link>
          <ul class="header__mobile-section" @click="toggleMobile">
            <li class="header__profile">
              <router-link to="/">
                <div class="header__profile-icon">
                  <el-icon><Grid /></el-icon>
                </div>
                Bosh sahifa
              </router-link>
            </li>
            <li
              v-for="(cat, index) of countcategory"
              :key="index"
              class="header__profile"
            >
              <router-link :to="`/sub/${cat.id}`" class="header__mobile-col">
                <div class="header__profile-icon">
                  <el-icon><Notebook /></el-icon>
                </div>
                {{ cat.title }}
              </router-link>
            </li>
            <li v-if="role == 'student'" class="header__profile">
              <router-link to="/profile/request">
                <div class="header__profile-icon">
                  <el-icon><User /></el-icon>
                </div>
                Profil
              </router-link>
            </li>
            <li v-else class="header__profile">
              <router-link to="/login">
                <div class="header__profile-icon">
                  <el-icon><User /></el-icon>
                </div>
                Profil
              </router-link>
            </li>
            <li>
              <el-button class="header__exit" @click="exit">
                <div class="header__profile-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.75998 22.27H8.88999C13.33 22.27 15.47 20.52 15.84 16.6C15.88 16.19 15.58 15.82 15.16 15.78C14.76 15.74 14.38 16.05 14.34 16.46C14.05 19.6 12.57 20.77 8.87998 20.77H8.74998C4.67998 20.77 3.23998 19.33 3.23998 15.26V8.74001C3.23998 4.67001 4.67998 3.23001 8.74998 3.23001H8.87998C12.59 3.23001 14.07 4.42001 14.34 7.62001C14.39 8.03001 14.74 8.34001 15.16 8.30001C15.58 8.27001 15.88 7.90001 15.85 7.49001C15.51 3.51001 13.36 1.73001 8.88999 1.73001H8.75998C3.84999 1.73001 1.74998 3.83001 1.74998 8.74001V15.26C1.74998 20.17 3.84999 22.27 8.75998 22.27Z"
                      fill="#292D32"
                    />
                    <path
                      d="M9 12.75H20.38C20.79 12.75 21.13 12.41 21.13 12C21.13 11.59 20.79 11.25 20.38 11.25H9C8.59 11.25 8.25 11.59 8.25 12C8.25 12.41 8.59 12.75 9 12.75Z"
                      fill="#292D32"
                    />
                    <path
                      d="M18.15 16.1C18.34 16.1 18.53 16.03 18.68 15.88L22.03 12.53C22.32 12.24 22.32 11.76 22.03 11.47L18.68 8.12C18.39 7.83 17.91 7.83 17.62 8.12C17.33 8.41 17.33 8.89 17.62 9.18L20.44 12L17.62 14.82C17.33 15.11 17.33 15.59 17.62 15.88C17.76 16.03 17.96 16.1 18.15 16.1Z"
                      fill="#292D32"
                    />
                  </svg>
                </div>
                {{ $t("admin.exit") }}
              </el-button>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <div class="header__right">
      <div v-if="role == 'admin'"></div>
      <router-link v-else to="/favorites" class="header__favorites">
        <svg
          width="35px"
          height="35px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 6C6 4.89543 6.89543 4 8 4H16C17.1046 4 18 4.89543 18 6V21L12 15L6 21V6Z"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
      <el-button class="header__btn" @click="toggle" v-if="toggleTheme">
        <el-icon><Moon /></el-icon>
      </el-button>
      <el-button class="header__btn" @click="toggle" v-else>
        <el-icon><Sunny /></el-icon>
      </el-button>
      <div class="header__user">
        <el-dropdown>
          <span class="header__drop" v-if="role">
            {{ user.name }} {{ user.lname }}
            <!-- <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon> -->
          </span>
          <span class="header__drop" v-else> </span>
          <template #dropdown>
            <el-dropdown-menu class="header__dropmenu">
              <el-dropdown-item v-if="role == 'admin'">
                <router-link to="/admin/prof" class="header__item">
                  <div>Profil</div>
                  <el-icon><Operation /></el-icon>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item v-else-if="role">
                <router-link to="/profile/request" class="header__item">
                  <div>Profil</div>
                  <el-icon><Operation /></el-icon>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item class="header__item" v-else> </el-dropdown-item>
              <el-dropdown-item v-if="role" class="header__item" @click="exit">
                Chiqish
                <el-icon><SwitchButton /></el-icon>
              </el-dropdown-item>
              <el-dropdown-item v-else></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div class="header__avatar" v-if="role">
          <img
            class="picture"
            v-if="user.avatar"
            :src="`${this.$store.getters.url}/` + user.avatar"
            alt=""
          />

          <svg
            v-else
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="header__avatar" v-else>
          <div @click="toLogin">
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import iconUz from "@/assets/imgs/uzbekistan.png";
import iconRU from "@/assets/imgs/russia.png";
import iconEn from "@/assets/imgs/usa.png";
// import logo from "@/assets/lib/logo.svg";

import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      // logo,
      avatar: [],
      nav: false,
      isShowLang: false,
      show: false,
      icon1: iconUz,
      icon2: iconRU,
      icon3: iconEn,
      profile: {},
      searchHeader: "",
      toggleSearch: true,
      mobile: true,
      mobileSide: null,
    };
  },

  watch: {
    searchHeader(val) {
      if (val && this.toggleSearch) {
        this.$store.dispatch("searchHome", val);
      } else {
        this.$store.commit("searchHome", []);
      }
      this.toggleSearch = true;
    },
    $route(to) {
      if (to.query) {
        this.searchHeader = to.query.title;
        this.toggleSearch = false;
        this.searchList(this.searchHeader);
      }
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "category",
      "resbooks",
      "role",
      "toggleTheme",
      "findUsers",
      "userAdmin",
      "headerSearch",
      "headerLogo",
      "searchHomeGet",
      "countcategory",
      "url",
    ]),
  },
  methods: {
    ...mapActions(["apiToggleTheme", "findUser", "findAdmin", "searchList"]),

    toLogin() {
      this.$router.push("/login");
      this.$store.dispatch("setLayout", "auth-layout");
    },
    toggleMobile() {
      this.mobileSide = !this.mobileSide;
    },
    exit() {
      if (confirm("Tizimdan chiqmoqchimisiz?")) {
        this.$store.dispatch("exit");
        this.$router.push("/");
      }
    },
    backHome() {
      this.$store.commit("headerSearch", false);
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
    },
    langIsShow() {
      this.isShowLang = !this.isShowLang;
      if (this.isShowLang) {
        this.$refs.dropdownRef.classList.add("d-block");
        this.$refs.dropdownRef.classList.remove("d-none");
      } else {
        this.$refs.dropdownRef.classList.add("d-none");
        this.$refs.dropdownRef.classList.remove("d-block");
      }
    },
    toggle() {
      this.apiToggleTheme(!this.toggleTheme);
    },

    goBook(title) {
      this.searchList(title);
      this.searchHome = "";
      this.$store.commit("searchHome", []);
      this.$router.push({ path: "/bookview", query: { title: title } });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/part/header.scss";
@import "@/styles/media.scss";
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.aside__menu {
  display: none;
}
</style>

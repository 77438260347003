import axios from "axios";
import { ElNotification } from "element-plus";
import {ElMessage} from 'element-plus'
export const giveBook = {
  state: () => ({
    result: [],
    listBook: [],
    allGiveBook: [],
    countGiveBook: 0,
    giveBookStat:{}
  }),
  getters: {
    giveBookStat({giveBookStat}){
      return giveBookStat
    },
    result(state) {
      return state.result;
    },
    getBook(state) {
      return state.listBook;
    },
    allGiveBook(state) {
      return state.allGiveBook.map((book) => {
        book.data = book.data
          ? new Date(book.data).toLocaleString()
          : "Hali topshirilmagan";
        book.createdAt = new Date(book.createdAt).toLocaleString();
        return book;
      });
    },
    countGiveBook(state) {
      return state.countGiveBook;
    },
  },
  mutations: {
    setGiveBookStat(state,payload){
      state.giveBookStat = payload
    },
    result(state, payload) {
      state.result = payload;
    },
    listBook(state, payload) {
      state.listBook = payload;
    },
    getGiveBook(state, payload) {
      state.allGiveBook = payload;
    },
    addGiveBook(state, payload) {
      state.allGiveBook = [payload, ...state.allGiveBook];
      state.countGiveBook += 1
    },
    countGiveBook(state, payload) {
      state.countGiveBook = payload;
    },
    updateGiveBook(state,payload){
      state.allGiveBook = state.allGiveBook.map(book => {
        if (book._id == payload._id) return payload
        return book
      })
    }
  },
  actions: {
    goList(context, payload) {
      axios
        .get(`${context.getters.url}/givebook/select`, {
          params: { value: payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("result", res.data);
          }
        });
    },
    listBook({ getters, commit }) {
      axios
        .get(`${getters.url}/paperbook/forgivebook`, {
          headers: getters.header,
        })
        .then((res) => {
          commit("listBook", res.data);
        });
    },
    addGiveBook(context, payload) {
      axios
        .post(`${context.getters.url}/givebook`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          context.commit("notification", {
            type: "success",
            message: "Muvafaqqiyatli",
          });
          context.commit("addGiveBook", res.data);
        }).catch((err) => {
          ElMessage({
            type: 'warning',
            message: err.response?.data?.msg
          })
          
        })
    },
    getGiveBook(context, params) {
      context.commit("loading", true);
      axios
        .get(`${context.getters.url}/givebook`, {
          headers: context.getters.header,
          params
        })
        .then((res) => {
          // console.log(res.data)
          context.commit("getGiveBook", res.data.givebooks);
          context.commit("countGiveBook", res.data.count);
          context.commit('setGiveBookStat', {
            count: res.data.count,
            count_give: res.data.count_give,
            count_base: res.data.count_base            
          })
          context.commit("loading", false);
        });
    },
    changeGiveBook({commit,getters},payload){
      if (!payload) return false
      axios.get(`${getters.url}/givebook/change/${payload}`,{
        headers: getters.header
      })
      .then(res => {
        commit('updateGiveBook',res.data)
        ElMessage({
          type: 'success',
          message: 'Kitob holati o`zgartirildi'
        })
      })
    }
  },
};

import axios from "axios"

export  const profile ={
  state: () => ({
    allRequest:[],
    downloadBook:[],
    findUser:{
			user:{
				login:''
			}
		},
		findAdmin:{}
  }),
  getters:{
    downloadBook(state) {
			return state.downloadBook
		},
    allRequest(state){
      return state.allRequest
    },
    findUsers(state) {
			return state.findUser
		},
		userAdmin(state) {
			return state.findAdmin
		},
  },
  mutations:{
    downloadBook(state, payload) {
			state.downloadBook = payload
		},
    addRequest(state, payload) {
			state.allRequest.push(payload)
		},
    requestbook(state, payload) {
			state.allRequest = payload
		},
    findUser(state, payload) {
			state.findUser = payload
		},
		
		findAdmin(state, payload) {
			state.findAdmin = payload
		},
    saveProfile(state, payload) {
			state.findUser = payload
		},
		saveProfileAdmin(state, payload) {
			state.findAdmin = payload
		},
  },
  actions:{
    getDownloadBook(context,payload){
			axios.get(`${context.getters.url}/downloadbook`,{
				headers: context.getters.header,
		})
		.then(res=> {
			if (res.status==200){
				let books = res.data.downloadbook
					books = books.map(book => {
					
						if (book.book?.author) {
							book.book.author = JSON.parse(book.book?.author)
						}
						let year = new Date(book.book?.year)
						if (book.book?.year) {
							book.book.year = year.getFullYear()
						}
						book.createdAt = new Date(book.createdAt).toLocaleString()
						if (book.book?.language == 0) {
							book.book.language = "O'zbek tili"
						} else if (book.book?.language == 1) {
							book.book.language = "Rus tili"
						} else {
							book.book.language = "Ingliz tili"
						}
						return book
					})
				context.commit('downloadBook',res.data.downloadbook)
			}
			})
		},
    downloadFile(context,payload){
			axios.post(`${context.getters.url}/downloadbook`,{book:payload},{
				headers: context.getters.header,
		})
		.then(res=> {
			if (res.status==201){
				// context.commit('paperBook',res.data.paperbooks)
			}
			})
		},
    getRequest(context,payload){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/requestbook`,{
          
          headers: context.getters.header,
      })
      .then(res=> {
        if (res.status==200){
          context.commit('requestbook',res.data)
          context.commit('loading',false)
        }
      })
    },
    addRequest(context,payload){
      axios.post(`${context.getters.url}/requestbook`, payload, {
        headers: context.getters.header
      })
      .then(res => {
        if (res.status == 201) {
          context.commit('addRequest', res.data)
        }
      })
    },
		findAdmin(context){
      return axios.get(`${context.getters.url}/users/profile`,{
          headers: context.getters.header,
      })
      
    },
    findUser(context,){
      return axios.get(`${context.getters.url}/auth/finduser`,{
          headers: context.getters.header,
      })
    },
    async saveProfile({getters,commit,dispatch},payload){
      let res = await axios.put(`${getters.url}/auth/update/${payload._id}`,payload,{
          headers: getters.header,
      })
      if (res.status==201){
        // commit('saveProfile',res.data)
        commit('user', res.data)
        dispatch('findUser')
      }

    },
		saveAdminProfile({getters,commit,dispatch},payload){
      axios.put(`${getters.url}/users/${payload._id}`,payload,{
          headers: getters.header,
      })
      .then(res=> {
          if (res.status==201){
            commit('saveProfileAdmin',res.data)
            commit('user', res.data)
            dispatch('findAdmin')
          }
      })
    },
  }
}
import axios from "axios"

export const paperBook = {
  state:() => ({
    paperbooks:[],
    findAuthor: '',
    languageBook:[],
    allPaperBook:[],
    countPaperBook:0,
    countGive:0,
    editBook:'',
    findInvent:0,
    paperview:{}
  }),
  getters:{
    countGive(state){
      return state.countGive
    },
    paperbooks(state){
      return state.paperbooks
    },
    paperview(state){
      return state.paperview
    },
    getFindAuthor(state){
      return state.findAuthor
    },
    allPaperBook(state){
      return state.allPaperBook
    },
    countPaperBook(state){
      return state.countPaperBook
    },
    editBook(state){
      return state.editBook
    },
    findInvent(state){
      return state.findInvent
    },
  },
  mutations:{
    setCountGiveBook(state,payload){
      state.countGive = payload
    },
    paperBook(state,payload){
      state.allPaperBook = payload
    },
    paperview(state,payload){
      state.paperview = payload
    },
    searchUdk(state,payload){
      state.paperbooks = payload
    },
    findAuthor(state,payload){
      state.findAuthor = payload
    },
    findInvent(state,payload){
      state.findInvent = payload
    },
    countPaperBook(state,payload){
      state.countPaperBook = payload
    },
    editBook(state,payload){
      state.editBook = payload
    },
    addPaperBook(state,payload){
      state.allPaperBook = [...payload,...state.allPaperBook]
      state.countPaperBook+= payload.length
    },
    paperBookDelete(state,payload){
      let index = state.allPaperBook.findIndex(paper => paper._id == payload)
      if (index !==-1){
          state.allPaperBook.splice(index,1)
          state.countPaperBook-=1
      }
    },
    upPaperBook(state,payload){
      let index = state.allPaperBook.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allPaperBook[index] = payload
      }
    },
  },
  actions:{
    allPaperBook(context,payload){
      context.commit('loading',true)
      axios.get(`${context.getters.url}/paperbook`,{
          params:{...payload},
          headers: context.getters.header,
      })
      .then(res=> {
        console.log(res.data)
        context.commit('paperBook',res.data.paperbooks)
        context.commit('countPaperBook',res.data.count)
        context.commit('setCountGiveBook',res.data.countGive)

        context.commit('loading',false)
        
      })
    },
    
    deletePaperBook(context,payload){
      axios.delete(`${context.getters.url}/paperbook/${payload}`,{
        headers: context.getters.header
      })
      .then(res => {
          if (res.status == 200){
              context.commit('paperBookDelete',payload)

          }
      })
    },
    editPaperBook(context,payload){
        return axios.get(`${context.getters.url}/paperbook/${payload}`,{
            headers: context.getters.header
        })
        
    },
    savePaperBook(context,payload){
      axios.put(`${context.getters.url}/paperbook/${payload._id}`,payload,{
          headers: context.getters.header
      })
      .then(res=> {
        if (res.status==201){
          context.commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          context.commit('upPaperBook',payload)
        }
      })
    },
    searchUdkCode(context,payload){
      axios.get(`https://udc-api.perviy-vestnik.ru/api/search?request=${payload}`)
      .then(res => {
          if (res.data !=='error'){
              res.data = res.data.map(book => {
              
                  return book 
              })
              context.commit('searchUdk',res.data)
          } else {
              context.commit('searchUdk',[])
          }
      })
    },
    findAuthor(context,payload){
      return axios.get(`${context.getters.url}/paperbook/searchauthor`,{
          params:payload,
          headers: context.getters.header,
      })
    },
    async getGroupExcel(context,payload){
      // console.log(payload);
      return await axios.get(`${context.getters.url}/paperbook/excel`,{
        params: payload
      })
    },
    async getDownload(context,payload){
      return await axios.get(`${context.getters.url}/paperbook/barcode/${payload}`)
    },
    findInvent(context,payload){
      axios.get(`${context.getters.url}/paperbook/lastinvent`,{
        params:payload,
        headers: context.getters.header,
    })
    .then(res =>{
      if(res.status == 200){
        context.commit('findInvent',res.data)
      }
    })
    }
  }
}
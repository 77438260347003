<template>
  <div class="sidebar">
    <div class="sidebar__card">
      <div >
        <div class="sidebar__avatar">
          <svg fill="#ccc" width="40px" height="40px" viewBox="0 0 24 24" id="user" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color"><path id="primary" d="M21,20a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2,6,6,0,0,1,6-6h6A6,6,0,0,1,21,20Zm-9-8A5,5,0,1,0,7,7,5,5,0,0,0,12,12Z" style="fill: #a6a6a6;"></path></svg>
        </div>
        <div class="sidebar__user">
          {{$t('sidebar.welcome')}} <p>{{findUsers.lname}} {{findUsers.name}}</p>
        </div>
      </div>
    </div>
    <el-menu  
      default-active="1" 
      class="el-menu-vertical-demo" 
      background-color="#F4F6F8" 
      text-color="#000" 
      active-text-color="#ffd04b" 
    >
    
      <el-menu-item index="1" class="sidebar__link">
        <router-link to="/">
          <el-icon>
            <homeFilled/>
          </el-icon>
          <span class="sidebar__title">Bosh sahifa</span>
        </router-link>
      </el-menu-item>
      <el-menu-item index="2" class="sidebar__link">
        <router-link to="/allBook" >
          <el-icon>
            <reading/>
          </el-icon>
          <span class="sidebar__title">
            Kitoblarim
          </span>
        </router-link>
      </el-menu-item>
      <el-menu-item index="3" class="sidebar__link">
        <div class="sidebar__exit" @click="exit">
            <el-icon><switch-button/></el-icon>
            Tizimdan chiqish
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  methods:{
    ...mapActions(['findUser']),
    exit(){
      if (confirm('Tizimdan chiqmoqchimisiz?')){
        this.$store.dispatch('exit')
        this.$store.dispatch('allCategory')
        this.$store.dispatch('allBook')
        this.$router.push('/')
      }
    },
  },
  computed:{
    ...mapGetters(['findUsers', 'user'])
  },
  mounted(){
    this.findUser(this.user.id)
  }
}
</script>

<style>

</style>
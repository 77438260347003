import axios from 'axios'

export const student = {
    state: () => ({
        specialties:[],
    }),
    getters: {
        specialties({specialties}){
            return specialties
        }
    },
    mutations: {
        set_specialties(state,payload){
            state.specialties = [...payload]
        }
    },
    actions:{
        async get_groups({getters},payload){
            return axios.get(`${getters.url}/api/group`,{
                headers: getters.header,
                params: {...payload}
            })
        },
        async get_students({getters},payload){
            return axios.get(`${getters.url}/api/student`,{
                headers: getters.header,
                params: {...payload}
            })
        },
        new_students({getters,commit},payload){
            axios.post(`${getters.url}/api/student`,payload,{
                headers: getters.header,                
            })
            .then(res => {
                commit('add_students',res.data.data)
            })
        },
        get_specialties({getters,commit},payload){
            axios.get(`${getters.url}/api/specialty`,{
                headers: getters.header,
                params: {...payload}
            })
            .then(res => {
                commit('set_specialties',[...res.data.data])
            })
        },
        async getStudentByID({getters},payload){            
            return await axios.get(`${getters.url}/givebook/findstudent/${payload}`,{
                headers: getters.header
            })
        },
        async getBookByInvent({getters},payload){            
            return await axios.get(`${getters.url}/givebook/findinvent/${payload}`,{
                headers: getters.header
            })
        },
    }
}
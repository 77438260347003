<template>
  <div :class="`main ${toggleTheme ? '' : 'black'}`">
    <el-row >
      <el-col :span="3" class="main__aside no-print">
        <admin-aside/>
      </el-col>
      <el-col :span="21" class="main__view">
        <div :class="`admin__main ${toggleTheme ? '' : 'black'}`">
          <aside-item class="no-print"/>
          <router-view/>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AdminAside from '@/components/admin/AdminAside.vue';
import SideBar from '@/components/Side-bar.vue'
import AsideItem from '../components/user/UserAside.vue'
import {  mapActions, mapGetters } from 'vuex'
export default {
    components: { AsideItem, SideBar, AdminAside },
    computed:{
      ...mapGetters(['toggleTheme'])
    },
    methods: {
      ...mapActions(['checkUser'])
    },
    mounted(){
        this.checkUser()
    }

}
</script>

<style lang="scss">
@import '@/styles/view/home.scss';
@import '@/styles/media.scss';
</style>
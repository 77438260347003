<template>
  <component :is="layout" />
  <notif />
</template>

<script>
import NotificationBox from "@/components/NotificationBox.vue";
import NavMenu from "@/components/NavMenu.vue";
import AdminLayout from "@/layout/adminLayout.vue";
import UserLayout from "@/layout/userLayout.vue";
import AuthLayout from "@/layout/authLayout.vue";
import StudentLayout from "@/layout/studentLayout.vue";
export default {
  components: {
    notif: NotificationBox,
    "admin-layout": AdminLayout,
    "user-layout": UserLayout,
    "auth-layout": AuthLayout,
    "student-layout": StudentLayout,
    NavMenu: NavMenu,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
  methods: {
    checkUser() {
      if (this.$cookies.isKey("arm-user")) {
        this.$store.dispatch("checkUser", this.$cookies.get("arm-user"));
      } else {
        // this.$router.push('/')
        // this.$store.dispatch('notification',{
        //   type:'error',
        //   message:'Сеанс окончено! Авторизуйтесь еще раз!'
        // })

        this.$store.dispatch("setLayout", "user-layout");
      }
      if (this.$cookies.isKey("theme-fon")) {
        this.$store.dispatch(
          "apiToggleTheme",
          this.$cookies.get("theme-fon").toggle
        );
      }
    },
  },
  watch: {
    $route(to) {
      if (to.name === "home") {
        this.$store.commit("headerSearch", false);
        this.$store.commit("headerLogo", false);
      }
    },
  },
  mounted() {
    document.title = "UBSU eLib";
    this.checkUser();
    this.$store.dispatch("allCategory");
    this.$store.dispatch("allSubcategory");
    this.$store.dispatch("allBook");
    this.$store.dispatch("allLanguage");
  },
};
</script>

<style lang="scss">
@import "./styles/reset.scss";
@import "./styles/grid.scss";
@import "./styles/main.scss";
@import "./styles/component/book.scss";
</style>

<template>
  <div :class="`home ${toggleTheme ? '' : 'black'}`">
    <AdminDashboard v-if="role=='admin'"/>
    <BooksList v-else 
      :books="books" 
      title="Kutubxonada mavjud kitoblar"
      @changePage="(val)=>{}"
      />
  </div>
</template>

<script>
import AdminDashboard from '@/components/admin/AdminDashboard.vue';
import BooksList from '@/components/BooksList.vue';

export default {
  name: 'HomeView',
  components: {
    AdminDashboard,
    BooksList,
},
  computed:{
    books(){
      return this.$store.getters.books
    },
    role(){
      return this.$store.getters.role
    },
    toggleTheme(){
      return this.$store.getters.toggleTheme
    }
  },
  watch:{
    $route(to){
      this.$store.dispatch('allBook',to.query.next)
    }
  },
  mounted(){
    this.$store.dispatch('allCategory')
    this.$store.dispatch('allCategoryCount')
  }
}
</script>
<style  lang="scss">
  @import '@/styles/main.scss';
</style>

import axios from "axios"

export const getBook = {
  state: () =>({
    get_book: {},
    bookCount:{}
  }),
  getters: {
    getBookById(state) {
      return state.get_book
    },
    bookCount(state) {
      return state.bookCount
    }
  },
  mutations: {
    setBookById(state, payload) {
      state.get_book = payload
    },
    bookCount(state, payload) {
      state.bookCount = payload
    }
  },
  actions: {
    async apiGetBookById({getters, commit}, payload) {
      if(payload){
        let res = await axios(`${getters.url}/paperbook/forusers/${payload}`)
        if(res.status === 200) {
          let book = res.data
            let year = new Date(book.year)
            if (book.author) {
              book.author = book.author.toString()
            }
            book.year = year.getFullYear()
            book.language = book.language.name
            book.cat = book.category.title ? book.category.title : ''
            commit('setBookById',book)
        }
      }
    },
    async apiGetElectronBookById({getters, commit}, payload) {
      if(payload){
        let res = await axios(`${getters.url}/book/show/${payload}`)
        if(res.status === 200) {
          let book = res.data
            let year = new Date(book.year)
            if (book.author) {
              book.author = JSON.parse(book.author)
              book.author = book.author.toString()
            }
            book.year = year.getFullYear()
            if (book.language == 0) {
              book.language = "O'zbek tilida"
            } else if (book.language == 1) {
              book.language = "Rus tili"
            } else {
              book.language = "Ingliz tili"
            }
            book.cat = book.category.title ? book.category.title : ''
            book.isActive = 1
            commit('setBookById',book)
        }
      }
    },
    getBookCount(context) {
      axios.get(`${context.getters.url}/book/countbytype`, {
        headers: context.getters.header
      }).then(res => {

        if (res.status === 200) {
          context.commit('bookCount', res.data)
        }
      })
			
		},
  }
}